<template>
  <div class="login-wrapper">
    <div class="login-logo-cell" @click="$router.push('/')">
      <img class="logo" src="@/assets/img/logowhite.png" />
      <div class="logo-label">专注北美计算机留学求职</div>
    </div>
    <div class="ver-panel">
      <div class="ver-head">
        <div class="l-title">绑定手机号</div>
        <div class="l-desc">应相关政策要求，账号需绑定手机号，绑定后可继续使用CSON</div>
      </div>
      <el-form :model="changeForm" :rules="rules2" ref="changeForm">
        <el-form-item prop="phone">
          <div class="input-select-group" :class="'select' + changeForm.select">
            <el-select
              v-model="changeForm.select"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in countrys"
                :label="item.label"
                :key="item.val"
                :value="item.val"
              ></el-option>
            </el-select>
            <div class="accountSeperator"></div>
            <div class="flex-col">
              <el-input v-model="changeForm.phone" placeholder="请输入手机号">
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="changeForm.code" placeholder="请输入短信验证码">
            <el-button
              @click="sendVerificationCode2"
              :disabled="sended"
              slot="suffix"
              class="btn btn-getcode"
              >{{
                sended ? `${countDown}秒后再次发送` : "获取短信验证码"
              }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0">
          <div class="ver-foot">
            <el-button
              class="btn-submit"
              style="width: 100%"
              type="primary"
              @click="submitForm2"
              >完成验证</el-button
            >
            <div class="ver-foot">
              <el-button style="width: 100%" type="text" @click="directTo"
                >直接进入</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getUserInfoData,
  sendUpdatePhoneSms,
  checkUserOldPhoneAndCode,
  sendVerificationCode,
  bandEmailOrPhone,
} from "@/service/index.js";
export default {
  components: {},
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      setTimeout(() => {
        if (!regex.test("+" + this.changeForm.select + value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      verifyForm: {
        code: "",
      },
      changeForm: {
        select: "86",
      },
      phone: "",
      isVerified: false,
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules2: {
        phone: [{ required: true, validator: checkMobile, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      sended: false,
      countDown: 60,
      countrys: [
        {
          label: "+86 中国",
          val: "86",
        },
        {
          label: "+1 美国 & 加拿大",
          val: "1",
        },
        {
          label: "+886 台湾",
          val: "886",
        },
        {
          label: "+852 香港",
          val: "852",
        },
        {
          label: "+61 澳大利亚",
          val: "61",
        },
        {
          label: "+44 英国",
          val: "44",
        },
        {
          label: "+49 德国",
          val: "49",
        },
        {
          label: "+353 爱尔兰",
          val: "353",
        },
        {
          label: "+41 瑞士",
          val: "41",
        },
        {
          label: "+31 荷兰",
          val: "31",
        },
        {
          label: "+33 法国",
          val: "33",
        },
        {
          label: "+34 西班牙",
          val: "34",
        },
        {
          label: "+64 新西兰",
          val: "64",
        },
        {
          label: "+7 俄罗斯",
          val: "7",
        },
        {
          label: "+82 韩国",
          val: "82",
        },
        {
          label: "+81 日本",
          val: "81",
        },
        {
          label: "+971 阿拉伯联合酋长国",
          val: "971",
        },
      ],
      interval: null,
    };
  },
  mounted() {},
  methods: {
    submitForm2() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          bandEmailOrPhone(
            `${this.changeForm.select}${this.changeForm.phone}`,
            this.changeForm.code
          ).then((res) => {
            if (res.success) {
              this.interval && clearInterval(this.interval);
              this.sended = false;
              this.countDown = 60;
              this.$message.success("绑定手机成功！");
              setTimeout(() => {
                window.location.href = "/";
              }, 1000);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
    sendVerificationCode2() {
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      if (!this.changeForm.phone) {
        return this.$message.error("请输入手机号!");
      }
      if (!regex.test("+" + this.changeForm.select + this.changeForm.phone)) {
        return this.$message.error("请输入正确的手机号!");
      }
      this.slideValidation(() => {
        sendVerificationCode(
          `${this.changeForm.select}${this.changeForm.phone}`
        ).then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.sended = true;
            this.interval = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(this.interval);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    directTo() {
      window.location.href = "/";
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../../../assets/images/login/bg.jpg) center no-repeat;
  background-size: cover;
  height: 100vh;
}
.ver-panel {
  width: 500px;
  padding: 30px 80px;
  border-radius: 40px;
  background: #fff;
  margin-bottom: 90px;
}
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 75px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.btn-getcode {
  display: inline-flex;
  border: 0;
  color: #007df7;
  padding: 8px 10px;
  cursor: pointer;
  &.is-disabled {
    color: #c0c4cc;
  }
}
.ver-panel ::v-deep .el-input__inner {
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
}
.ver-foot {
  text-align: center;
}
.ver-panel .btn-submit {
  width: 60%;
  margin: 0;
  border-radius: 10px;
}
.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}
.input-select-group {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 10px;
  border-color: #f8f8f8 !important;
  position: relative;
}
.input-select-group .el-select {
  width: 110px;
}
.input-select-group.select1 .el-select {
  width: 156px;
}
.input-select-group.select353 .el-select,
.input-select-group.select34 .el-select,
.input-select-group.select64 .el-select {
  width: 130px;
}
.input-select-group.select61 .el-select {
  width: 136px;
}
.input-select-group.select971 .el-select {
  width: 200px;
}

.accountSeperator {
  background: #ebeced;
  height: 22px;
  width: 1px;
}
.flex-col {
  flex: 1;
  min-width: 0;
}
.ver-phone-cell {
  font-size: 14px;
}
.login-logo-cell {
  text-align: center;
  margin-bottom: 30px;
  color: #ffffff;
  cursor: pointer;
  img {
    height: 42px;
  }
}
</style>


